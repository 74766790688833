import styled from "styled-components";
import { Screens } from "@constants/screens";
import * as Colors from "@constants/colors";
import { BlockHeader } from "@components/shared.styled";
import Button from "@components/button";

const Header = styled.section`
  background-color: ${({ backgroundColor = Colors.beachTealLighter }) =>
    backgroundColor};
  text-align: center;
  padding: 112px 0 112px;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const StyledBlockHeader = styled(BlockHeader)`
  max-width: 580px;
`;

const Description = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin: 40px auto 70px;
  max-width: 580px;
`;

const StyledButton = styled(Button)`
  min-width: 190px;
  max-width: 80%;
  padding: 15px 20px;
  font-size: 24px;
  line-height: 32px;

  :hover {
    background: ${Colors.blackLighter};
    border-color: ${Colors.blackLighter};
  }
`;

export { Header, StyledBlockHeader, Description, StyledButton };
