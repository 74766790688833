import React from "react";
import { StyledButton } from "./button.styled";
import * as Colors from "@constants/colors";

const Button = ({
  className,
  backgroundColor = Colors.beachTeal,
  borderColor = Colors.beachTeal,
  color = Colors.white,
  text,
  type,
  onClick,
}) => (
  <StyledButton
    className={className}
    onClick={onClick}
    color={color}
    type={type}
    backgroundColor={backgroundColor}
    borderColor={borderColor}
  >
    {text}
  </StyledButton>
);

export default Button;
