import styled from "styled-components";
import { Screens } from "@constants/screens";
import * as Colors from "@constants/colors";

const StyledButton = styled.button`
  outline: none;
  border-radius: 5px;
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  padding: 16px 24px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    background: ${Colors.beachTealDarker};
    border-color: ${Colors.beachTealDarker};
  }

  @media (max-width: ${Screens.xs}) {
    padding: 10px 15px;
    font-size: 18px;
    line-height: 1;
  }
`;

export { StyledButton };
