import React from "react";
import { Wrapper } from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Header,
  StyledBlockHeader,
  Description,
} from "./thanks-for-submission-form.styled";

const ThanksForSubmissionForm = ({
  backgroundColor,
  largeHeader,
  description,
}) => (
  <>
    <Header backgroundColor={backgroundColor}>
      <Wrapper>
        <StyledBlockHeader>{largeHeader}</StyledBlockHeader>
        <Description
          dangerouslySetInnerHTML={{
            __html: convertMarkdownToHtml(description),
          }}
        />
      </Wrapper>
    </Header>
  </>
);

export default ThanksForSubmissionForm;
